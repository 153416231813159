<template>
  <tr v-on:click="setHasRead(notification.id)"
      :id="'notification_item_' + notification.id"
      class="pointer"
      :style="'background:' + (!notification.has_read ? '#fffbed' : '#fff')">
    <td class="text-center">
      <i :class="notification.type_icon" class="font-scale-10 text-muted" />
    </td>
    <router-link v-if="notification.item_id && notification.type === 'task'"
                 :to="{ name: notification.route_name, params: { taskId: notification.item_id }}">
      <td class="nowrap-column">
        {{ notification.message }}1
      </td>
    </router-link>
    <router-link v-if="notification.item_id && notification.type === 'car'"
                 :to="{ name: notification.route_name, params: { carId: notification.item_id }}">
      <td class="nowrap-column">
        {{ notification.message }}2
      </td>
    </router-link>
    <td v-if="notification.item_id && notification.type !== 'car' && notification.type !== 'task'">
      {{ notification.message }}
    </td>
    <td class="nowrap-column">
      {{ notification.created_date }}
    </td>
  </tr>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "notification-item",

  props: {
    notification: {
      type: Object,
      default() {
        return this.$props.$_PaginationObject;
      },
    },
  },

  computed: {
    ...mapGetters("auth", ["authUser"]),
  },

  methods: {
    setHasRead(notificationId) {
      API.apiClient.put("/employee/" + this.authUser.id + "/notifications/" + notificationId).then(() => {
        document.getElementById("notification_item_" + notificationId).style.background = "#fff";
        this.EventBus.$emit("table-filter-changed");
      }).catch((error) => {
        errorHandler(error);
      });
    },
  },
};
</script>

<style scoped>

</style>
